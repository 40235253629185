<template>
    <div class="web-app">
        <section>
            <About heading="Software Development"
                   desc="The process wherein a set of tasks or programs are created or developed to promote and accelerate your business growth."/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">Custom Software Development Services</h3>
                        <p class="mb-0">Our developers can produce next-generation app development so that it
                            simplifies business complexities and takes your business to the lead. Our software solutions
                            generate disruptions within organizations and boost business productivity</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="software-development/1.png" heading="UI/UX Design and Development Service"
                                             desc="All our designs are based on detailed research, and therefore are one of their kind, and stand out in the crowd for the user to notice."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="software-development/2.png" heading="Mobile App Development Services"
                                             desc="We use the latest technologies in delivering unforgettable digital experiences to all our users, without differentiating on the platform."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="software-development/3.png" heading="AR and VR app development services"
                                             desc="Our team will create innovative applications that are created so that the users experience and understand your products and services in a better way."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/software-development/img.png" alt="#" class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="software-development/4.png" heading="Cross-Platform (Hybrid) Desktop and Mobile apps"
                                             desc="The same code base, along with being less expensive is what cross-platform supports and offers."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="software-development/5.png" heading="Cloud Application Development Services"
                                             desc="Building custom applications and migrating legacy architectures is what we can harness from this infrastructure."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="software-development/6.png" heading="App maintenance and Support services"
                                             desc="In-depth portfolio analysis is conducted to generate growth in your IT architecture, and also if a need arises to convert the already existing applications as well."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="Outstanding Software Solutions to boost your business"
                   desc="Software development services that are impactful, custom-made, and industry-specific are hard to find. This is where we enter - the best Software development company in Thrissur. We are your one-stop software development service providers who aid in the reliable digital transformation of your business."/>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Our Customer Software Development Process Involves"
                         headingdesc="We use the latest practices and methodologies to deliver apt solutions that stay within your budget and timelines."/>

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Planning"
                                    desc="We hear your needs, collect the required information and translate it into technical documentation to design a blueprint of the project."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Designing"
                                    desc="We present you with our plans and also add in your suggestions and custom-needs to create a design of the whole project."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Development"
                                    desc="Upon finalizing the plan, our team starts with the development part. Transparency and frequent project updates are promised from our end."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Testing"
                                    desc="After successful implementation, our QA team will test the product and fix the bugs and issues before releasing the product."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Deployment"
                                    desc="Finally, the product is released and constant monitoring is done to check for user experience and acceptance issues."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Maintenance"
                                    desc="Our team will be there for you at every stage with maintenance and other support services as we want our clients to have a smooth business operation."/>
                    </div>
                </div>

            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="What makes Xeoscript the best Software App development company in Thrissur, Kerala?"
                         headingdesc="Well, many reasons attribute to our success. We became the best-reviewed Software App development company in India, mostly due to our passion and a will to go that extra mile for our clients."/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="software/why-1.jpg" heading="Years of Expertise"
                                    desc="The developers give their entire expertise and hard work to come up with their innovative technology to achieve uniqueness."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="software/why-2.jpg" heading="Global Experience"
                                    desc="Our experience to have worked with clients spread across the world such as the USA, UK, Australia, etc. has made us the well-renowned Software app Development Company in Thrissur"/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="software/why-3.jpg" heading="Team of experts"
                                    desc="We are a bunch of software development stalwarts who are highly knowledgeable and have varied amounts of  industry experience who ensure quality and efficiency remain uncompromised."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="software/why-4.jpg" heading="Pocket-friendly Solutions"
                                    desc="We take our client's financial outline into consideration and work to meet their budget constraints. We want our clients to have a stress-free experience with us."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="software/why-5.jpg" heading="100’s of happy clients"
                                    desc="We keep ourselves in your shoes, that’s why every time there is a requirement for hiring a Software app development company in Thrissur; our clients make sure that they recommend us."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="software/why-6.jpg" heading="Dedicated Project Manager"
                                    desc="Our team understands the importance of a personalized touch that each client needs. Hence we have dedicated project managers for all our clients that improve coordination and communication."/>
                    </div>

                </div>
            </div>
        </section>

<!--        <section>-->
<!--            <GetAQuote/>-->
<!--        </section>-->

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
// import GetAQuote from '../components/GetAQuote';
import Component8 from '../components/single-components/Component-8';
import Component10 from '../components/single-components/Component-10';

export default {
    name: 'Software',
    metaInfo: {
        title: 'Top Custom Software Development Companies in Kerala',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript technologies is a leading Software development Company in Thrissur. We provide software development services that make your business stand out in crowd. '
            },
            {
                name: 'keywords',
                content: 'software development company in thrissur, top software company in thrissur, best software company in thrissur, best software company'
            }
        ]
    },
    components: { Component10, Component8, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>

<template>
    <div class="web-app">
        <section>
            <About heading="iOS Application Development"
                   desc="Elegant, unique, and simple, these are the three quintessences of iOS applications. If you are an entrepreneur, business owner, or startup owner, we are here to provide you with the best competitive solutions."/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">iOS Application Development Services</h3>
                        <p class="mb-0">All of Apple's products are covered, be it iPad, iPhone, Apple TV, or
                            Apple watch. Our expert team will be able to provide you with dynamic and adaptive iOS
                            solutions. We also strictly adhere to the iOS Human Interface guidelines to produce and
                            create iOS apps that are not only user friendly but also flamboyant.</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app-ios/1.png" heading="iOS app UI/UX"
                                             desc="A user-friendly interface along with the charisma that is prone to attract users is what we focus on and prioritize when it comes to the design aspects. "/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app-ios/2.png" heading="Enterprise iOS apps"
                                             desc="Our primary priority is to provide unique applications that provide you with a hassle-free workflow and engage your employees in a simple yet sophisticated way. "/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app-ios/3.png" heading="iOS App Consulting"
                                             desc="Being in the iOS app development industry for a while, we are well aware of the market trends and hence efficiently incorporate value-added features to attain the best results."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/mobile-app-ios/img.png" alt="#" class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app-ios/4.png" heading="Custom iOS Apps"
                                             desc="We are proficient in delivering customized iOS app development that gives you an instant solution and sticks to your immediate business requirement."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app-ios/5.png" heading="Support and Maintenance"
                                             desc="The success of an app is defined by the minor bug fixes, firmware updates, software upgrades, and timely maintenance that is provided from time to time."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app-ios/6.png" heading="iOS App testing"
                                             desc="Our QA testers communicate with the project managers as well as with developers to make sure that the consistency and workflow of the app remain intact."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="Excellent iOS apps to boost your business"
                   desc=" We are a mobile app development company, who has grown over the years with ample experience in iOS app development services. Our iOS app development is flexible, transparent, and makes use of cutting edge technology to provide powerful and functional solutions."/>
        </section>

        <section class="py-5">
            <div class="container">

                <heading heading="Why create an iOS app for your business?"/>

                <div class="row overflow-h">
                    <div class="col-12 col-lg-6 fl-x-cc">
                        <ul class="bullet-1 text-gray" data-aos="fade-right" data-aos-duration="500">
                            <li>Excellent user experience: iOS applications are innately customer-oriented and guarantee
                                a smooth and lucid user experience.
                            </li>
                            <li>Enhanced market penetration: iOS applications are widely favored and used in developed
                                nations hence giving your business a global reach.
                            </li>
                            <li>Guaranteed Security: Being one of the safest platforms, your business operations remain
                                secured with iOS applications.
                            </li>
                            <li>Earn higher ROI: Your business grows exponentially as you gain a competitive edge with
                                iOS applications.
                            </li>
                            <li>Scalable and Robust: iOS applications allow easy incorporation of features and
                                functionalities that are needed where your business grows.
                            </li>
                        </ul>
                    </div>

                    <div class="col-12 col-lg-6 fl-x-cc order-first order-lg-0 mb-4 mb-lg-0" data-aos="fade-left" data-aos-duration="500">
                        <img src="../../assets/images/service-page/mobile-app-ios/ios-img.png" alt="#"
                             class="img-fluid">
                    </div>

                </div>
            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="What makes Xeoscript the best iOS App development company in Thrissur, Kerala."
                         headingdesc=" As the number one iOS App development company in India, we ensure to have our team keep up with the international standards."/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ios/why-1.jpg" heading="Exquisite"
                                    desc="The developers give their entire expertise and hard work to come up with innovative technology to achieve uniqueness. "/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ios/why-2.jpg" heading="Result Driven"
                                    desc="Being the best iOS app development company in Thrissur, our proficient developers ensure that the result found after the development client satisfactory."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ios/why-3.jpg" heading="Expertise of developers"
                                    desc="Our tenure team puts in the right amount of effort to achieve and gain a positive result and happily face all the challenges that come our way. "/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ios/why-4.jpg" heading="Affordable and Best concept"
                                    desc="Our goal is to help you with services that suit your pocket as well as meets your business needs."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ios/why-5.jpg" heading="Happy client"
                                    desc="The satisfaction of the client is something that we never compromise on. This attributes to our reputation as the best-reviewed iOS app development company in Thrissur."/>
                    </div>

                </div>
            </div>
        </section>

<!--        <section>-->
<!--            <GetAQuote/>-->
<!--        </section>-->

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
// import GetAQuote from '../components/GetAQuote';
import Component10 from '../components/single-components/Component-10';

export default {
    name: 'iOS-app',
    metaInfo: {
        title: 'Mobile App Development Company in Thrissur, Kerala, India',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript is a top rated Mobile app development company in Thrissur, Kerala, India. We create interactive iOS, android and cross platform application.'
            },
            {
                name: 'keywords',
                content: 'mobile app development company in thrissur, ios development companies in thrissur, mobile app companies in thrissur, mobile app development services, android application company in thrissur, ios app development company'
            }
        ]
    },
    components: { Component10, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>

<template>

    <div class="row overflow-h">
        <div class="col-12 col-md-6 col-lg-4 mb-3">
            <Component5 img="KARNATAKA BANK.png" heading="Tax Gateway - Karnataka Bank"
                        details="Implemented CBDT/GST/Icegate"/>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-3">
            <Component5 img="SOUTH INDIAN BANK.png" heading="Tax Gateway - South Indian Bank"
                        details="Implemented CBDT/GST/Icegate"/>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-3">
            <Component5 img="DHANALAKSHMI.png" heading="Tax Gateway - Dhanlaxmi Bank"
                        details="Implemented CBDT/GST/Icegate"/>
        </div>
    </div>

</template>

<script>
import Component5 from './single-components/LatestWorksItem';

export default {
    name: 'Service-3',
    components: { Component5 }
};
</script>

<style scoped>

</style>

<template>
    <div class="web-app">
        <section>
            <About heading="Online Reputation Management (ORM)"
                   desc="Online reputation management simply means having a system in place that serves to produce, cultivate and flourish your online brand name."/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">What is the role of ORM in digital marketing?</h3>
                        <p class="mb-0">Creating a digital public image in a way so that it portrays the best
                            reviews. It also means providing an optimistic customer experience to the viewers by
                            deploying ideas to create, improvise and respond to the reviews that are placed online. ORM
                            also makes sure to throw attention to all the famous review sites.</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3 fl-x-cc">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="orm/1.png" heading="Online Referrals"
                                             desc="Managing customer feedback actively can take you to the lead and help you to build your referral list as well as trust amongst the customers."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="orm/2.png" heading="Boosts SEO"
                                             desc="It automatically boosts your SEO as it is an effective factor since it impacts search engine rankings directly."/>
                            </div>
                            <!--                            <div class="col-12">-->
                            <!--                                <Component10 img="mobile-app-androids/3.png" heading="Android App Consulting"-->
                            <!--                                             desc="We have been striving to resolve your concerns, whatsoever it may be and provide a complete solution for the same for all your Android app needs."/>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/orm/img.png" alt="#"
                             class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3 fl-x-cc">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="orm/3.png" heading="Improve Brand Visibility"
                                             desc="Blogs, Forums and social media play a vital role in letting the customers decide what to choose. With ORM we will improve the brand visibility."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="orm/4.png" heading=" Improve Sales"
                                             desc="Positive reviews will lead to improved sales as people tend to choose a product with more positive reviews before they go ahead with their purchase."/>
                            </div>
                            <!--                            <div class="col-12">-->
                            <!--                                <Component10 img="mobile-app-androids/6.png" heading="Android App testing"-->
                            <!--                                             desc="We deliver uncompromised testing solutions for android applications; every measure is taken to put in stringent quality standardization."/>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="Substantial ORM means Successful Business"
                   desc="With our variety of services in ORM, we make sure to bring in the best of the strategies that are defined to make your business look unique and make you look ideal and successful."/>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="What our Online Reputation Management Services Include"
                         headingdesc="We integrate various technologies to deliver the best suitable application that exponentially enhances the business productivity."/>

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Content Creation"
                                    desc="Creating positive content to generate an online reputation that will help you eradicate negative search results. This also includes SEO that will help you to attain the right reputation."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Online Monitoring"
                                    desc="The team makes sure to monitor other pages to ensure if you are being discussed so that the necessary actions can be taken. We also keep track of what and how it benefits your business."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Social Media"
                                    desc="We create social media account on popular sites and keep them under close monitor as it promotes your brand as well as helps in handling negative social comments."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Review management"
                                    desc="Review management being the primary means of reputation management is provided with a platform to manage each and every review related task."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Crisis Response"
                                    desc="Whenever there is a need to respond to a reputation attack, we provide the necessary steps to eliminate them and quickly bring your reputation back on track."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Positive reputation management"
                                    desc="We provide a variety of strategies to improve your brand image by mentioning your business on websites, blogs, forums as well as news sites."/>
                    </div>
                </div>

            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Why should you choose us? "
                         headingdesc="Being the best Android App development company in Thrissur, we ensure our clients get the best apps for their business that meet beyond their expectations."/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="orm/why-1.jpg" heading="Strategy"
                                    desc="We ensure that all our strategies are customised in such a way to bring in maximum output with the best prices."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="orm/why-2.jpg" heading="Value"
                                    desc="All our techniques are cost-effective and we make sure to leave no space for negative content and highlight only positive content."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="orm/why-3.jpg" heading="Results"
                                    desc="We make sure that you are left with the best services ever and that we lead you directly to top ranks."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="orm/why-4.jpg" heading="Confidential"
                                    desc="Having a non-disclosure agreement is part of our contract and we make sure that it remains confidential as well as utmost discreet until the end of time."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="orm/why-5.jpg" heading="Innovation"
                                    desc="Reputation management is what we improvise from time to time so that we meet and match the top search engines algorithmic changes."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="orm/why-6.jpg" heading="Service"
                                    desc="We assure our clients’ success online in the best possible ways. We also make sure to follow up with a dedicated team for the same."/>
                    </div>

                </div>
            </div>
        </section>

<!--        <section>-->
<!--            <GetAQuote/>-->
<!--        </section>-->

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
// import GetAQuote from '../components/GetAQuote';
import Component10 from '../components/single-components/Component-10';
import Component8 from '../components/single-components/Component-8';

export default {
    name: 'ORM',
    metaInfo: {
        title: 'Expert Digital Marketing Services To Grow Your Business',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript technology is a professional Digital marketing company in Thrissur - Kerala. We provide valued digital marketing services to our customers'
            },
            {
                name: 'keywords',
                content: 'digital marketing companies in thrissur, digital marketing in thrissur, social media marketing services in thrissur, internet marketing companies in thrissur, SEO companies in thrissur, SEO in thrissur'
            }
        ]
    },
    components: { Component8, Component10, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>

<template>
    <div class="web-app">
        <section>
            <About heading="Web Design in future"
                   desc="Our dedicated team of web designers and developers are fully determined to provide you with a solution to the best of our abilities. "/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">How website helps to grow business ?</h3>
                        <p class="mb-0">When it comes to web designing and development services, we are the
                            one-stop solution as we focus to deliver a conversion-oriented, customer-centric, and
                            user-friendly interface for website solutions. We invent and create websites that are
                            assured to upscale your business.</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500"
                                             img="website-development/1.png" heading="Website Development"
                                             desc="The solutions that we deliver make sure to attract user traffic in leaps and bounds as well as we are highly result-oriented to gain users from all over the world. "/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500"
                                             img="website-development/2.png" heading="Website Designing"
                                             desc="Web design services provided are unique and excellent. Our designers and developers are well-experienced in generating profound styles that captivates the users’ attention."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500"
                                             img="website-development/3.png" heading="Redesigning existing Websites"
                                             desc="Want to redesign your existing website and transform it from mundane to marvelous? Let us get that new makeover done for your website, we are sure you won’t regret it."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in"
                         data-aos-duration="500">
                        <img src="../../assets/images/service-page/website-development/img.png" alt="#"
                             class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500"
                                             img="website-development/4.png" heading="E-Commerce Development"
                                             desc="We will serve you with the best eCommerce website to upscale your online business, and build a larger customer circle on the go."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500"
                                             img="website-development/5.png" heading="Content Management Technique"
                                             desc="We develop and use the best content management system techniques to streamline your business and bring them to the global market arena."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500"
                                             img="website-development/6.png"
                                             heading="Design and the experience of the user"
                                             desc="Our UI/UX experts are sure to mesmerize the user with a website that looks compelling to self-invite themselves to your products and services."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="Website Design that Matters"
                   desc="Web designing and development have been our forte ever since technology evolved. The solutions we come up with are digitally transforming and feature-rich. Our professional team has gained experience not only from the theoretical part but also from the hands-on work around throughout all these years. "/>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="The technology we use"/>

                <div class="row fl-j-c">
<!--                    <div class="col-12 col-md-6 col-lg-4 mb-3">-->
<!--                        <Component6 img="service-page/website-development/php.png" heading="PHP Web Devellopment"-->
<!--                                    desc="We use this powerful server scripting language for making dynamic and interactive Web pages"/>-->
<!--                    </div>-->
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component6 img="service-page/website-development/pytho.png" heading="Python"
                                    desc="Python is a clear, concise and powerful programming language used to create web applications"/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component6 img="service-page/website-development/java.png" heading="Java Web Development"
                                    desc="Java, an object-oriented programming language is always the popular choice to build high-performance mobile applications."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component6 img="service-page/website-development/html.png" heading="HTML 5"
                                    desc="HTML5 is the latest and most enhanced version of HTML. This markup language is used to design powerful web pages"/>
                    </div>
<!--                    <div class="col-12 col-md-6 col-lg-4 mb-3">-->
<!--                        <Component6 img="service-page/website-development/word.png" heading="Wordpress Development"-->
<!--                                    desc="WordPress is a free, open-source website creation platform. Create a highly functional website or blog using WordPress."/>-->
<!--                    </div>-->

<!--                    <div class="col-12 col-md-6 col-lg-4 mb-3">-->
<!--                        <Component6 img="service-page/website-development/asp.png" heading="ASP.Net"-->
<!--                                    desc="ASP.NET is a web application framework that allows programmers to build dynamic web applications."/>-->
<!--                    </div>-->

                </div>

            </div>
        </section>

        <section class="py-5 bg-gray-1">
            <div class="container">
                <heading heading="Our Recent Web design works"
                         headingdesc="We brew up your dream project with our hard work, dedication, and years of expertise. We can always go the extra mile to see you satisfied."/>

                <div class="row">

                    <div class="col-12 col-lg-4 mb-3">
                        <Component5 img="webdesign-02.jpg" heading="Fednet - Federal Bank"
                                    details="Responsive Website Design"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <Component5 img="webdesign-03.jpg" heading="Esaf - Website Design"
                                    details="Responsive Website Design"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <component5 img="webdesign-03.png" heading="Galeto - Website Design"
                                    details="Responsive Website Design"/>
                    </div>
                </div>

                <div class="row fl-x-cc mt-5">
                    <div class="col-8 col-lg-3">
                        <router-link to="/our-works/" class="lego-btn xeo-btn-2 btn-block">View All Works</router-link>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5">
            <div class="container">
                <heading
                    heading="What makes Xeoscript the best Website design and development company in Thrissur, Kerala, India?"
                    headingdesc="We earned this tag of being the number one Website design and development company in India with lots of smart work and dedication. Here are a few reasons that make us who we are today:"/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="website/why-1.jpg" heading="On-time delivery"
                                    desc="We have a long-time reputation of never missing a deadline. We adhere to the given project timelines so that you get much-needed peace of mind in your life."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="website/why-2.jpg" heading="Quality Assurance"
                                    desc="We never fail to implement best practices and methodologies to ensure that you get top-notch quality and efficiency."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="website/why-3.jpg" heading="Team of Experts"
                                    desc="Our team of website designers and developers are passionate, hard-working, and dedicated to giving each client a personalized approach."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="website/why-4.jpg" heading="Affordable prices"
                                    desc="We use the tried and tested strategies to give you the web design that suits your business as well as your pockets."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="website/why-5.jpg" heading="Years of Expertise"
                                    desc="We have years of expertise in working with various clients across the globe helping us in widening our international exposure."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="website/why-6.jpg" heading="After-sales support"
                                    desc="To develop long-term client relations, our team will provide you with their relentless support even after project delivery."/>
                    </div>

                </div>
            </div>
        </section>

<!--        <section>-->
<!--            <GetAQuote/>-->
<!--        </section>-->

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
// import GetAQuote from '../components/GetAQuote';
import Component6 from '../components/single-components/Component-6';
import Component10 from '../components/single-components/Component-10';
import Component5 from '../components/single-components/Component-5';

export default {
    name: 'Web-design',
    metaInfo: {
        title: 'Top web design company in Thrissur, Kerala, India',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript is a leading web design company in Thrissur, Kerala offering solutions that are innovative, unique and that provide tangible business results. '
            },
            {
                name: 'keywords',
                content: 'web designing company in thrissur, website development company in thrissur, best web designing company in thrissur, web development services in thrissur, Ecommerce development company in thrissur'
            }
        ]
    },
    components: { Component5, Component10, Component6, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>

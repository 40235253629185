<template>

    <div class="mb-4">
        <div class="fl-x-c">
            <img :src="require('@/assets/images/service-page/' + img)"
                 alt="profile" class="profile-img">
            <div class="pl-3">
                <p class="mb-0" v-if="heading" v-html="heading"></p>
            </div>
        </div>
        <div class="mt-4">
            <p class="mb-0 fs--1 text-justify lh-2" v-if="desc" v-html="desc"></p>
        </div>
    </div>

</template>

<script>
export default {
    name: 'component-10',
    props: {
        img: {
            type: String,
            required: false,
            default: ''
        },
        heading: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

.profile-img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
}

</style>

<template>
    <div class="web-app of-h">
        <section>
            <About heading="Products"
                   desc="Explore our suite of solutions designed to streamline financial operations, enhance customer experiences, and ensure security across banking and tax processes. Our products are built to simplify and optimize your financial management with cutting-edge technology and user-centric features."/>
        </section>

        <section class="py-5" id="taxGateway">
            <div class="container">
                <div class="row fl-x-cc mb-5">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4 font-weight-bold">Tax Gateway</h3>
                        <p class="mb-0">Tax Gateway offers an all-in-one solution to simplify the tax payment and management process for both businesses and individuals. With
                            features like real-time tracking, automated reconciliation, and a range of payment options, it ensures a hassle-free, compliant experience for
                            users.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row overflow-h">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="tax-gateway/icons-with-bg/Streamlined Tax Payments.png"
                                             heading="Streamlined Tax Payments"
                                             desc="<b>Tax Gateway</b> simplifies the tax payment process by integrating with major government portals like GST, CBDT, and CBIC. This unified platform enables businesses and individuals to easily manage all their tax obligations in one place, reducing the complexity of dealing with separate systems and making tax compliance straightforward."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="tax-gateway/icons-with-bg/Flexible-Payment-Methods.png"
                                             heading="Flexible Payment Methods"
                                             desc="The platform supports multiple payment methods, including internet banking, credit/debit cards, UPI, and over-the-counter (OTC) options. This flexibility allows users to choose their preferred payment method, ensuring a smooth and convenient tax payment experience. Whether at home or on the go, making payments is simple and efficient."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/tax-gateway/Tax Gateaway.png" alt="#" class="w-50p lg-w-70p img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="tax-gateway/icons-with-bg/Real-time-Monitoring-&-Reports.png"
                                             heading="Real-time Monitoring & Reports"
                                             desc="With advanced features like real-time transaction monitoring and daily reporting, the Tax Gateway ensures full visibility into tax payments. Taxpayers can track their payments, generate reports, and verify transactions in real-time, which improves accuracy and ensures compliance."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="tax-gateway/icons-with-bg/Automated-Reconciliation-&-Compliance.png"
                                             heading="Automated <br> Reconciliation & Compliance"
                                             desc="<b>Tax Gateway</b> automates much of the tax collection process, reducing the risk of errors and ensuring that payments are processed correctly. This automated system generates end-of-day (EOD) files, which simplify reconciliation, and helps taxpayers remain compliant with regulatory requirements without manual intervention."/>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="fs-3 fs-lg-6 mb-4 text-center font-weight-bold mt-4">Implemented in</h3>
                <div class="row fl-j-c">
                    <div class="col-lg-4 col-6 mt-4 mt-lg-0 fl-x lg-fl-j-e">
                        <div class="pl-3 py-3">
                            <img src="../../assets/images/clients/federal_bank_logo.jpg" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-lg-4 col-6 mt-4 mt-lg-0 fl-x-cc">
                        <img src="../../assets/images/clients/logo-sib.png" class="img-fluid" alt="">
                    </div>
                    <div class="col-lg-4 col-6 mt-4 mt-lg-0 fl-x lg-fl-j-s">
                        <div class="pr-3">
                            <img src="../../assets/images/clients/KTK.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-lg-4 col-6 mt-4 mt-lg-3 fl-x-cc">
                        <div class="p-4">
                            <img src="../../assets/images/clients/Dhanalakshmi.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-lg-4 col-6 mt-4 mt-lg-3 fl-x-cc">
                        <img src="../../assets/images/clients/kvb-logo.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </section>
        <!--        customer self service portal-->
        <section class="py-5" id="customerServicePortal">
            <div class="container">
                <div class="row fl-x-cc mb-5">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4 font-weight-bold">Customer Self-Service Portal</h3>
                        <p class="mb-0">The Customer Self-Service Portal transforms how customers access and manage their banking documents with ease and convenience. Offering 24/7
                            access and streamlining the process for both users and banks, it significantly enhances the overall banking experience.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row overflow-h">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="customer-service-portal/icons-with-bg/Simplified-Document-Retrieval.png"
                                             heading=" Simplified Document Retrieval"
                                             desc="The <b>Customer Self-Service</b> Portal provides an easy, intuitive way for customers to access their important banking documents. Whether it's loan statements, certificates, or deposit-related paperwork, customers can retrieve what they need quickly without stepping foot in a branch. This feature offers complete control over personal financial records, making it a crucial tool for efficient banking management."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="customer-service-portal/icons-with-bg/Immediate-Access-Anytime-Anywhere.png"
                                             heading="Immediate Access Anytime, Anywhere"
                                             desc="With 24/7 access, the portal allows customers to manage their financial documents on their schedule. No more waiting for bank hours or long queues—customers can download statements, request documents via email, or instantly access certificates. This convenience ensures that managing finances becomes a seamless part of everyday life, without delays or hassle."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/customer-service-portal/Customer-Self-Service-Portal.png" alt="#" class="w-50p lg-w-90p img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="customer-service-portal/icons-with-bg/Efficiency-at-Your-Fingertips.png"
                                             heading="Efficiency at Your Fingertips"
                                             desc="The portal doesn’t just save time; it also enhances operational efficiency. Banks can reduce administrative overhead by offering a self-service option that enables customers to access their documents independently. This translates to reduced costs and quicker service for both customers and bank staff, creating a more streamlined process for all."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="customer-service-portal/icons-with-bg/Revolutionizing-Customer-Experience.png"
                                             heading="Revolutionizing Customer Experience"
                                             desc="More than just a time-saver, the Customer Self-Service Portal fosters stronger customer relationships. By offering transparency and ease of access to critical documents, it builds trust and improves overall satisfaction. As customers can now manage their financial documents independently, it strengthens their confidence in the bank’s ability to cater to their needs."/>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="fs-3 fs-lg-6 mb-4 text-center mt-4 font-weight-bold">Implemented in</h3>
                <div class="row fl-j-c">
                    <div class="col-lg-3 col-6 fl-x-cc">
                        <img src="../../assets/images/clients/federal_bank_logo.jpg" class="img-fluid" alt="">
                    </div>
                </div>

            </div>
        </section>
        <!--         PFMS Account Validation-->
        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-5">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 font-weight-bold mb-4"> PFMS Account Validation</h3>
                        <p class="mb-0">The PFMS Account Validation module streamlines financial transactions by ensuring accurate account information and minimizing errors. With
                            features like real-time tracking, automatic file management, and enhanced security, it simplifies the validation process for institutions, boosting
                            efficiency and compliance.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row overflow-h">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="pfms/icons-with-bg/Accurate-Account-Validation.png"
                                             heading="Accurate Account Validation"
                                             desc="The PFMS Account Validation module enhances the accuracy of financial transactions by automatically validating bank account information. It ensures that transactions are directed to the correct accounts, reducing errors and ensuring smoother financial operations, particularly for institutions dealing with large volumes of transactions."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="pfms/icons-with-bg/Enhanced-Security-&-Compliance.png"
                                             heading="Seamless File Management"
                                             desc="This module works seamlessly with SFTP servers to securely transfer validation files between systems. It automatically processes account details, generating validation files and ensuring that all information is accurately parsed and sent without manual intervention. This system minimizes human error and speeds up the process."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/pfms/PFMS-Account-Validation.png" alt="#" class="w-50p lg-w-90p img-fluid">

                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="pfms/icons-with-bg/Real-time-Tracking-&-Resend-Capabilities.png"
                                             heading="Real-time Tracking & Resend Capabilities"
                                             desc="With real-time tracking, users can monitor the status of account validation transactions as they happen. In case of transmission errors or delays, the system automatically triggers a resend mechanism, ensuring no data is lost and that financial processing remains uninterrupted."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="pfms/icons-with-bg/Enhanced-Security-&-Compliance.png"
                                             heading="Enhanced Security & Compliance"
                                             desc="Security is a priority in financial processing, and the PFMS Account Validation module provides a secure method for validating and exchanging information. This ensures that all data is protected during transmission and that the system complies with industry regulations, providing both safety and reliability for financial institutions."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <!--         Internet Banking Customization-->
        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-5">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 font-weight-bold mb-4"> Internet Banking Customization</h3>
                        <p class="mb-0">Internet Banking Customization platform empowers banks to create personalized online banking experiences that cater to both user preferences
                            and security needs. With features like custom workflows, third-party tool integrations, and multi-device support, this solution enhances user
                            satisfaction and accessibility while maintaining robust security.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row overflow-h">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="ecommerce/1.png" heading="Tailored Online Banking Experience"
                                             desc="The Internet Banking Customization platform allows banks to tailor the online banking experience to their specific needs. From personalized dashboards to custom workflows, this solution enables banks to offer a unique, user-friendly interface that aligns with customer preferences and the bank’s branding."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="ecommerce/2.png" heading="Personalized Security Measures"
                                             desc="Banks can integrate personalized security features such as two-factor authentication, biometric login, and real-time fraud monitoring. These customized security features not only protect customer data but also enhance the user’s sense of control and security while banking online."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/internet-banking/Internet Banking Customization.png" alt="#" class="w-50p lg-w-90p img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="ecommerce/4.png" heading="Seamless Integration with Third-Party Tools"
                                             desc="Internet Banking Customization supports integration with third-party tools, such as accounting software, investment platforms, and payment services. This seamless integration allows customers to manage all their financial activities in one place, creating a unified experience that enhances user satisfaction."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="ecommerce/5.png" heading="Multi-Device Accessibility"
                                             desc="The customized internet banking platform offers multi-device support, ensuring a consistent experience across desktops, tablets, and mobile phones. Whether customers are at home or on the go, they can access their accounts and manage their finances seamlessly, increasing convenience and user engagement."/>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="fs-3 fs-lg-6 mb-4 mb-lg-5 text-center mt-lg-5 font-weight-bold mt-4">Implemented in</h3>
                <div class="row fl-j-c">
                    <div class="col-lg-4 col-6 mt-4 mt-lg-0 fl-x lg-fl-j-e">
                        <div class="pl-3 py-3">
                            <img src="../../assets/images/clients/federal_bank_logo.jpg" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-lg-4 col-6 mt-4 mt-lg-0 fl-x-cc">
                        <img src="../../assets/images/clients/logo-sib.png" class="img-fluid" alt="">
                    </div>
                    <div class="col-lg-4 col-6 mt-4 mt-lg-0 fl-x lg-fl-j-s">
                        <div class="pr-3">
                            <img src="../../assets/images/clients/KTK.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--        <section class="overflow-h">-->
        <!--            <Quote heading="A Great Mobile App Leads to Great Business"-->
        <!--                   desc="We build reliable and high-quality on-demand eCommerce applications to expedite your business."/>-->
        <!--        </section>-->

        <!--        <section class="py-5 bg-gray-1">-->
        <!--            <div class="container">-->
        <!--                <heading heading="Our Recent Ecommerce Application"-->
        <!--                         headingdesc="We brew up your dream application with our hard work, dedication, and years of expertise. We can always go the extra mile to see you satisfied and content."/>-->

        <!--                <div class="row">-->
        <!--                    <div class="col-12 col-lg-4 mb-3">-->
        <!--                        <Component5 img="ecom-02.jpg" heading="Glocart - Ecommerce App"-->
        <!--                                    details="Ecommerce Application Development"/>-->
        <!--                    </div>-->
        <!--                    <div class="col-12 col-lg-4 mb-3">-->
        <!--                        <Component5 img="ecom-01.jpg" heading="Minibells - Ecommerce App"-->
        <!--                                    details="Ecommerce Application Development"/>-->
        <!--                    </div>-->
        <!--                    <div class="col-12 col-lg-4 mb-3">-->
        <!--                        <Component5 img="ecom-03.jpg" heading="Minimart - Ecommerce App"-->
        <!--                                    details="Ecommerce Application Development"/>-->
        <!--                    </div>-->
        <!--                </div>-->

        <!--                <div class="row fl-x-cc mt-5">-->
        <!--                    <div class="col-8 col-lg-3">-->
        <!--                        <router-link to="/our-works/" class="lego-btn xeo-btn-2 btn-block">View All Works</router-link>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </section>-->

        <!--        <section>-->
        <!--            <GetAQuote/>-->
        <!--        </section>-->

    </div>
</template>

<script>
import About from '../components/About-comp';
// import Quote from '../components/Quote';
// import Heading from '../components/Heading';
// import Component7 from '../components/single-components/Component-7';
// import GetAQuote from '../components/GetAQuote';
// import Component8 from '../components/single-components/Component-8';
import Component10 from '../components/single-components/Component-10';
// import Component5 from '../components/single-components/Component-5';

export default {
    name: 'All Products Page',
    metaInfo: {
        title: 'Mobile App and Web development Company in Thrissur, Kerala',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Planning on building a large scale, custom-made and high-performing software solution for your business? Look no further as we at Xeoscript Technologies should be able to do the job for you.'
            },
            {
                name: 'keywords',
                content: 'software company in thrissur, it companies in thrissur, web designing company in thrissur, mobile app development company in thrissur,digital marketing services in thrissur'
            }
        ]
    },
    components: { Component10, About }
};
</script>

<style scoped>

</style>

<template>
    <div class="web-app">
        <section>
            <About heading="Digital Marketing"
                   desc="By proper use of digital marketing technologies, businesses can upgrade their brand performances easily. They can present relevant information and attract the right clients to choose their products or services."/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">Advantage of digital marketing for your business</h3>
                        <p class="mb-0">Marketing done on online platforms is known as digital marketing.
                            Being fast and result-oriented, digital marketing is gaining popularity for working
                            effectively in any industry type.</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="digital-marketing/1.png" heading="Cost-effect than traditional marketing"
                                             desc="Digital marketing, unlike traditional marketing, doesn’t require a large amount of time and money for the promotional activities to give results"/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="digital-marketing/2.png" heading="Better conversion rates"
                                             desc="Digital marketing strategies are designed for better business revenues. The marketing campaigns will reach the right audiences thus helping you improve your conversion rates."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="digital-marketing/3.png" heading="Gain visibility"
                                             desc="Digital marketing is the best platform to enhance your outreach and earn customers from across the globe."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/digital-marketing/img.png" alt="#" class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="digital-marketing/4.png" heading="Get real-time results"
                                             desc="The major benefit of digital marketing is that the results can be measured in real-time and helps you understand where further necessary steps need to be taken."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="digital-marketing/5.png" heading="Build a brand reputation"
                                             desc="You can align your business objectives with your digital marketing strategies and build your own brand image."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="digital-marketing/6.png" heading="Get a competitive advantage"
                                             desc="You can easily compete with large businesses through effective digital marketing which otherwise takes time and money through traditional marketing"/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="A Great Mobile App Leads to Great Business"
                   desc="Ignite your business visibility with our cutting-edge digital marketing tactics."/>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Our Digital marketing services"
                         headingdesc="We are known as the best digital marketing company in Thrissur for our innovative and out-of-the-box strategies and practices."/>

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Search Engine Optimization"
                                    desc="Gain better rankings for your business website through our effective SEO methods."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Social Media Marketing"
                                    desc="Get better traction and Influence your customer purchasing decisions through the right SMM tactics."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="PPC Marketing"
                                    desc="Our PPC campaigns will help you generate more clicks as we use strategies that target the right audience at the right time and price."/>
                    </div>
                </div>

            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Why should you choose us as your digital marketing service providers?"
                         headingdesc="Well, many reasons contribute to our success. We became the best-reviewed digital marketing company in India, mostly due to our passion and a will to go that extra mile for our clients."/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="digital-marketing/why-1.jpg" heading="Years of Expertise"
                                    desc="The developers give in their entire expertise and hard work to come up with their innovative technology to achieve uniqueness. "/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="digital-marketing/why-2.jpg" heading="Global Experience"
                                    desc="Our experience to have worked with clients spread across the world such as the USA, UK, Australia, etc. has made us the well-renowned digital marketing Company in Thrissur"/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="digital-marketing/why-3.jpg" heading="Team of digital marketing experts"
                                    desc="We are a bunch of digital marketing stalwarts who are highly knowledgeable and have varying amounts of industry experience who ensure quality and efficiency remain uncompromised."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="digital-marketing/why-4.jpg" heading="Pocket-friendly Solutions"
                                    desc="We consider our client's financial outline and work to meet their budget constraints. We want our clients to have a stress-free experience with us."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="digital-marketing/why-5.jpg" heading="100’s of happy clients"
                                    desc="We keep ourselves in your shoes, that’s why every time there is a requirement for hiring a digital marketing company in Thrissur; our clients make sure that they recommend us."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="digital-marketing/why-6.jpg" heading="Dedicated Project Manager"
                                    desc="Our team understands the importance of a personalized touch that each client needs. Hence we have dedicated project managers for all our clients that improve coordination and communication."/>
                    </div>

                </div>
            </div>
        </section>

<!--        <section>-->
<!--            <GetAQuote/>-->
<!--        </section>-->

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
// import GetAQuote from '../components/GetAQuote';
import Component10 from '../components/single-components/Component-10';
import Component8 from '../components/single-components/Component-8';

export default {
    name: 'Digital-marketing',
    metaInfo: {
        title: 'Expert Digital Marketing Services To Grow Your Business',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript technology is a professional Digital marketing company in Thrissur - Kerala. We provide valued digital marketing services to our customers'
            },
            {
                name: 'keywords',
                content: 'digital marketing companies in thrissur, digital marketing in thrissur, social media marketing services in thrissur, internet marketing companies in thrissur, SEO companies in thrissur, SEO in thrissur'
            }
        ]
    },
    components: { Component8, Component10, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>

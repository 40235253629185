<template>
    <div class="bs-9" data-aos="fade-up" data-aos-duration="500">
        <div class="d-flex p-6 bg-2">
            <img :src="require('@/assets/images/works/' + img)" alt="profile"
                 class="img-fluid">
        </div>
        <div class="px-4 py-3 text-center bg-2">
            <h6 class="mb-1 fs-1" v-if="heading" v-html="heading"></h6>
            <p class="mb-0 text-gray fs--1" v-if="details" v-html="details"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-5',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        details: {
            type: String,
            required: false,
            default: ''
        },
        img: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

</style>

<template>
    <div class="web-app">
        <section>
            <About heading="Academy Management System"
                   desc="Our Academy management system is one such platform for all your educational needs."/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">Why Academy Management System?</h3>
                        <p class="mb-0">Currently, an online platform to handle even the minute detail of an education system is not a luxury, but a necessity. This
                            innovative platform comes with features that enable smooth student-teacher interactions, and include modules to track students progress, conduct
                            examinations, operate admin roles and so on.
                        </p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="ams/1.png" heading="Multidimensional Control"
                                             desc="By enabling multidimensional control over various modules of the system, AMS gives an easy interface to supervise different branches."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="ams/2.png" heading="Evaluation of students performance"
                                             desc="The most important feature of any academy system must be the student performance analysis module. AMS has a deep analysis routine to assess various aspects of performance in a continuous mode. This overall analysis platform helps to boost students to perform to their best ability, every time."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="ams/3.png" heading="Go Paperless and Stay Global"
                                             desc="Implementing a sustainable and global system adds value to AMS. We are glad to hold our social commitments, with no compromise on quality and security. "/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/ams/main.png" alt="#" class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="ams/4.png" heading="Ease of Use"
                                             desc="The modular approach of AMS implies easy access to its users. With a simple alignment, it is easy to access different forums, attendance data, timetables and exam schedules etc. Student analysis and performance evaluation is a touch away with a well-defined database."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="ams/5.png" heading="Effective Student and Staff management"
                                             desc="The modular approach of AMS divides the whole system into students, staff, various departments and operations, and payment sections."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="ams/6.png" heading="Efficient Utilisation of resources"
                                             desc="Improved productivity and reduced workload are attractions that make it difficult to deny adopting AMS. By efficiently utilising the available resources, AMS guarantees the healthy development of its beneficiaries."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="A world-class solution with the best managed institutions"
                   desc="AMS includes state of the art features and detailed solutions for even the minute aspects in running an academic institution. Highly rated for its credible and error-free operation, AMS is trusted by many and is extremely easy to operate and access. Have no break in continuing your institution's and students' growth profile, amdist all this new normal phase."/>
        </section>

        <section class="py-5">
            <div class="container pt-md-4">
                <!--                <heading heading="Our Customer Software Development Process Involves"-->
                <!--                         headingdesc="We use the latest practices and methodologies to deliver apt solutions that stay within your budget and timelines."/>-->

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-3 mb-4" v-for="(i, index) in data" v-bind:key="index">
                        <Component16 :data="i" @openModal="openModal(i)"/>
                    </div>
                </div>
                <modal class="ams-modal" enter-animation="slideInDown" ref="viewMore" :title="item.title" @hidden="item={}">
                    <p class="fs-1">{{ item.desc }}</p>
                    <ul class="bullet-1">
                        <li v-for="(j, index) in item.points" v-bind:key="index">{{ j.point }}</li>
                    </ul>
                </modal>
            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-y-cc">
                    <div class="col-10 col-lg-8  text-center">
                        <p class="fs-4 fs-lg-8">Main Feature Highlights of AMS for students, teachers, staff, and admin</p>
                    </div>
                </div>

                <div class="row fl-y-cc">
                    <div class="col-12 col-md-10 col-lg-8 mb-4 text-center py-4">
                        <img src="../../assets/images/service-page/ams/main1.png" class="img-fluid" alt="#">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <ul class="bullet-1">
                            <li>Profile and dashboard:Set up your profile and login to access the modules. Register with AMS to own a profile and access interesting modules. Manage
                                fees collection, attendance, and receiving notifications
                            </li>
                            <li>
                                Communicate and collaborate: use the chat tools and reports to communicate and collaborate with other staff members as well as students. Release and
                                get notifications to stay updated.
                            </li>
                            <li>
                                Functional Divisions: Manage various functions of the system like profile management, exams, calendars, clubs schedules, hostel and transportation,
                                course, students, teaching staff, calendar, clubs, events, library, hostel, transportation and payment management.
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-lg-6">
                        <ul class="bullet-1">
                            <li>
                                Virtual classes and study corners: Access is given to all respective virtual sessions. It also allows you to join various study corners for further
                                discussions and understanding of topics. Get access to more knowledge by extra references from major books.
                            </li>
                            <li>
                                Reports and logs: Generate reports and manage logs to track the system performance and reach. For example, track attendance and manage timetables
                                easily through the user-friendly modules and generate reports.
                            </li>
                            <li>
                                Assignments, Projects and Examination: Periodic assessment of students can benefit teachers to provide the right feedback and extend further help as
                                needed. AMS is a platform to handle assignments and projects that enables detailed analysis of the subjects.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Advantages of an online academic management system"
                         headingdesc=""/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ams/why-1.png" heading="Improved productivity"
                                    desc="With better use of time and resources, productivity is increased. Convert the time and effort of the entire staff for improved data accuracy and organization."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ams/why-2.png" heading="Better communication"
                                    desc="With easy access from the comfort of your space, students, teachers, and parents can communicate with each other in better ways. It accounts for improved productivity as well."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ams/why-3.png" heading="More systematic"
                                    desc="Defining and managing timetables has always been a tedious task. With effective management software, the effort spent on timetable management can be eased and the system can work systematically."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ams/why-4.png" heading="Lesser human workforce"
                                    desc="A major human workforce is reduced as the need to manage students and school activities is eliminated. The absence of maintaining a physical school building and premises eases a big headache. It enables lesser staffing and thus amounts to reduced expenses. "/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ams/why-5.png" heading="More Parent-friendly"
                                    desc="Transparency between teachers and parents improves with improved communication and cloud-based system access. Parents can partner with the child’s growth by regularly tracking their progress."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ams/why-6.png" heading="Easy tracking per student"
                                    desc="Along with studies, extra-curricular activities are important for a child’s overall growth. With such online management tools, identifying and tracking progress in their area of interest is made easy."/>
                    </div>

                </div>
            </div>
        </section>

<!--        <section>-->
<!--            <GetAQuote/>-->
<!--        </section>-->

    </div>
</template>

<script>
import Component10 from '../components/single-components/Component-10';
import Component16 from '../components/single-components/Component-16';
// import GetAQuote from '../components/GetAQuote';
import Component7 from '../components/single-components/Component-7';
import Heading from '../components/Heading';
import Quote from '../components/Quote';
import About from '../components/About-comp';
import ams from '../../assets/AMScontents.json';

export default {
    name: 'Academic-mgnt',
    metaInfo: {
        title: 'Mobile App and Web development Company in Thrissur, Kerala',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Planning on building a large scale, custom-made and high-performing software solution for your business? Look no further as we at Xeoscript Technologies should be able to do the job for you.'
            },
            {
                name: 'keywords',
                content: 'software company in thrissur, it companies in thrissur, web designing company in thrissur, mobile app development company in thrissur,digital marketing services in thrissur'
            }
        ]
    },
    components: { Component10, Component16, Component7, Heading, Quote, About },
    data () {
        return {
            data: '',
            item: ''
        };
    },
    beforeMount () {
        this.data = ams;
        console.log('this.items', this.data);
    },
    methods: {
        openModal (i) {
            this.item = i;
            this.$refs.viewMore.show();
        }
    }

};
</script>

<style scoped>

</style>

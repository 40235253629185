<template>
    <div class="our-works">
        <section class="header container mt-4">
            <Heading
                heading="Customized software solutions at your disposal"
                headingdesc="We at Xeoscript are passionate about data. We understand how important data is and its part for the success in your business"
            />
        </section>
        <section class=" our-works-component">
            <div class="filters text-center mb-5 py-4 bg-2" data-aos="fade-up" data-aos-duration="500">
                <ul class="filter-list">
                    <li class="filter" v-bind:class="{ active: currentFilter === 'ALL' }" v-on:click="setFilter('ALL')">
                        All
                    </li>
                    <li class="filter" v-bind:class="{ active: currentFilter === 'WEB' }" v-on:click="setFilter('WEB')">
                        Web Development
                    </li>
                    <li class="filter" v-bind:class="{ active: currentFilter === 'MOB' }" v-on:click="setFilter('MOB')">
                        Mobile Applications
                    </li>
<!--                    <li class="filter" v-bind:class="{ active: currentFilter === 'ECOM' }"-->
<!--                        v-on:click="setFilter('ECOM')">E - commerce-->
<!--                    </li>-->
<!--                    <li class="filter" v-bind:class="{ active: currentFilter === 'DIG' }"-->
<!--                        v-on:click="setFilter('DIG')">Digial Marketing-->
<!--                    </li>-->
                    <!--                    <li class="filter" v-bind:class="{ active: currentFilter === 'OTH' }" v-on:click="setFilter('OTH')">-->
                    <!--                        Other-->
                    <!--                    </li>-->
                </ul>
            </div>

            <transition-group class="projects " name="projects">
                <div v-for="(i, index) in items" v-bind:key="index">
                    <div :class="[i.class]" v-if="currentFilter === i.category || currentFilter === 'ALL'">
                        <component :is="i.components" v-bind="i" :index="index" data-aos="fade-up"
                                   data-aos-duration="500"/>
                    </div>
                </div>
            </transition-group>
        </section>
<!--        <section>-->
<!--            <GetAQuote/>-->
<!--        </section>-->
    </div>
</template>

<script>
import Heading from './components/Heading';
import OurWorkComponent1 from './components/OurWorkComponent1';
import OurWorkComponent2 from './components/OurWorkComponent2';
import OurWorkComponent3 from './components/OurWorkComponent3';
import OurWorkComponent4 from './components/OurWorkComponent4';
import OurWorkComponent5 from './components/OurWorkComponent5';
// import GetAQuote from './components/GetAQuote';
import work from '../assets/work.json';

export default {
    name: 'OurWork',
    metaInfo: {
        title: 'Mobile App and Web development Company in Thrissur, Kerala',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Planning on building a large scale, custom-made and high-performing software solution for your business? Look no further as we at Xeoscript Technologies should be able to do the job for you.'
            },
            {
                name: 'keywords',
                content: 'software company in thrissur, it companies in thrissur, web designing company in thrissur, mobile app development company in thrissur,digital marketing services in thrissur'
            }
        ]
    },
    components: {
        Heading,
        OurWorkComponent1,
        OurWorkComponent2,
        OurWorkComponent3,
        OurWorkComponent4,
        OurWorkComponent5
        // GetAQuote
    },
    data () {
        return {
            currentFilter: 'ALL',
            items: ''
        };
    },
    beforeMount () {
        this.items = work;
        console.log('this.items', this.items);
    },
    methods: {
        setFilter: function (filter) {
            this.currentFilter = filter;
        }
    }
};

</script>
<style>

.bg-white {
    background: white;
}

.bg-esaf {
    background-color: #c73431;
}

.bg-cyber {
    background-color: #17316e;
}

.bg-sure {
    background-color: #fcb53d;
}

.bg-saneesh {
    background-color: #272727;
}

.bg-orange {
    background-color: #1c222e;
}

.bg-korb {
    background-color: #b39283;
}

.bg-medico {
    background-color: #627b78;
}

.bg-galeto {
    background-color: #f5f5f5;
}

.bg-Contract {
    background-color: #274466;
}

.bg-viva {
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #f96367), color-stop(100%, #f9575b), color-stop(100%, #f95457));

}

.bg-easy {
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #81b5da), color-stop(60%, #d5e7f6), color-stop(100%, #e6eff6));

}

.bg-vet {
    background-color: #186b66;
}

.bg-minimart {
    background-color: #3b9d52;
}

.bg-nila {
    background-color: #027ebe;
}

.bg-lb {
    background-color: #2f2f2f;
}

.bg-lb1 {
    background-color: #067fe5;
}

.bg-kakaserry {
    background-color: #045e8a;
}

.bg-gradient {
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #c9dae5), color-stop(40%, rgba(234, 237, 238, 0.62)), color-stop(100%, #c9e7ff));
}

.bg-reache {
    background-color: #e5eff9;
}
.bg-ktk{
    background-color: #92278f;
}
.bg-sib{
    background-color: #c4161c;
}
.bg-kvb{
    background-color: #00854a;
}
.bg-dhan{
    background-color: #54075b;
}

.filter-list {
    list-style: none;
}

.filter-list li {
    display: inline-block;
}

.our-works .header h3 {
    quotes: "“" "”" "‘" "’";
    font-family: "Poppins-Regular", serif;
    color: #505050;
    font-weight: 600;
    padding-left: 2.5em;
    padding-right: 2.5em;
}

.our-works .header h3::before {
    content: open-quote;
    font-size: 1.5em;
}

@media screen and (max-width: 992px) {

    .our-works .header h3 {
        padding-left: 1em;
        padding-right: 1em;
    }
}
</style>
<style scoped>
.filter {
    font-family: "Poppins-Regular", serif;
    padding: 6px 6px;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.35s;
    margin-left: 2em;

}

.filters {
    /*position: sticky;*/
    /*top: 79px;*/
    /*z-index: 1;*/
}

.filter.active {
    color: #00c0ff;
}

.filter:hover {
    background: lightgray;
}

.projects {
    margin-bottom: 50px;
    margin-top: 25px;
}

.projects-enter {
    transform: scale(0.5) translatey(-80px);
    opacity: 0;
}

.projects-leave-to {
    transform: translatey(30px);
    opacity: 0;
}

.projects-leave-active {
    position: absolute;
    z-index: -1;
}

.project {
    transition: all 0.35s ease-in-out;
    margin: 10px;
    box-shadow: 0 2px 8px lightgrey;
    border-radius: 3px;
    width: 180px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .filter-list li {
        margin-left: 6px;
        margin-right: 6px;
    }

}

</style>

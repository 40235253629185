seo/why-5.jpght" data-aos-duration="500"
<template>
    <div class="web-app">
        <section>
            <About heading="Top-notch SEO services in Thrissur"
                   desc="As a business in the age of technology, investing in SEO will take your business to unimaginable levels. Count on Xeoscript Technologies to define your SEO goals today."/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">Benefits of SEO for your company</h3>
                        <p class="mb-0">SEO is one of the best and most affordable digital marketing
                            techniques for any company. Here are some of the ways you can benefit if you are ranked
                            higher in SERPS.</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="seo/1.png" heading="Get access to organic traffic"
                                             desc="Unlike traditional marketing mediums, SEO is one of the best inbound marketing strategies to generate organic traffic, no matter the type of business."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="seo/2.png" heading="Enhanced user experience"
                                             desc="A well SEO-optimised page with clear, concise content, interlinks, great speed, and improved site navigation will be able to wow your audiences."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="seo/3.png" heading="Better conversion rates and ROI"
                                             desc="If your webpage is SEO-optimised the right way, you can expect high conversion rates and ROI. Which means more subscriptions, leads, and sales!"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/seo/img.png" alt="#" class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="seo/4.png" heading="Improved brand visibility"
                                             desc="When you are ranked higher than your competitors on search engines, you make your brand more visible to your customers and new audiences."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="seo/5.png" heading="Get customer insights "
                                             desc="Want to know how many customers stayed on your website for more than 10 minutes? SEO can give you this information and many such valuable insights."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="seo/6.png" heading="Improved credibility"
                                             desc="When you're featured on the first page on search engines, it increases your brand's integrity. Which means more visitors and minimal bounce rates."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="SEO made easy"
                   desc="Google keeps changing its ranking algorithms now and then. Our team evolves with each change and can serve you better with our unbeatable SEO strategies. Call us to book a consultation with one of our SEO experts. We offer SEO services in Thrissur, Kerala, Pan-India, and across the globe."/>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Our SEO Services Includes"
                         headingdesc="Xeoscript Technologies have a range of tailor-made services to boost the SEO of your website that aids in better reach and business awareness."/>

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Crafting quality content"
                                    desc="SEO-optimized content will help you attract prospective leads. From finding suitable keywords to creating infographics, we can do it all."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="On-page SEO services"
                                    desc="As a leading SEO company in Thrissur, we can use suitable on-page SEO techniques to boost your rankings on major search engines."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Conducting SEO audits"
                                    desc="Our team at Xeoscript Technologies can ensure that your website adheres to best practices by identifying SEO issues, user experience problems, and much more."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Local/International SEO"
                                    desc="We can optimize your presence using localization signals, changing your URL structure, managing citations amongst many other services."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Content marketing strategies "
                                    desc="Rely on Xeoscript Technologies to curate a content plan for your business so that you can market your products and services to a wider audience."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Link building assistance"
                                    desc="As an experienced SEO service provider in Kerala, we can create guest posts and articles with backlinks to improve your search engine rank on Google."/>
                    </div>
                </div>

            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Why choose our SEO services in Kerala?"
                         headingdesc="No business is the same. You need a unique SEO strategy formulated specifically for your business to enhance your visibility online. Count on Xeoscript Technologies for top quality SEO services in India and overseas."/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="seo/why-1.jpg" heading="Team of talents"
                                    desc="Our SEO experts are well-versed in the changing trends and complex strategies that help your business become visible to the right customers."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="seo/why-2.jpg" heading="Quality experience"
                                    desc="We have vast experience in SEO industrial trends and the latest practices. Being the best SEO company in Thrissur, we give our dedication and personalized attention to each project."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="seo/why-3.jpg" heading="Transparency"
                                    desc="We always hold onto our core values that also include transparency at every level. You can see all the project updates starting from initiation to execution."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="seo/why-4.jpg" heading="Customized services"
                                    desc="We understand that every business has its own needs. We cater to those requirements and create solutions for smoother operations."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="seo/why-5.jpg" heading="Satisfied clientele"
                                    desc="Our testimonials are the visible proof. Over the years, we were lucky to have references from our existing clients showing our satisfied client relations."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="seo/why-6.jpg" heading="Affordable services"
                                    desc="Believe it or not. We give all this and more at affordable pricing without any discrepancies in the quality"/>
                    </div>

                </div>
            </div>
        </section>

<!--        <section>-->
<!--            <GetAQuote/>-->
<!--        </section>-->

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
// import GetAQuote from '../components/GetAQuote';
import Component10 from '../components/single-components/Component-10';
import Component8 from '../components/single-components/Component-8';

export default {
    name: 'SEO',
    metaInfo: {
        title: 'Expert Digital Marketing Services To Grow Your Business',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript technology is a professional Digital marketing company in Thrissur - Kerala. We provide valued digital marketing services to our customers'
            },
            {
                name: 'keywords',
                content: 'digital marketing companies in thrissur, digital marketing in thrissur, social media marketing services in thrissur, internet marketing companies in thrissur, SEO companies in thrissur, SEO in thrissur'
            }
        ]
    },
    components: { Component8, Component10, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>

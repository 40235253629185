<template>
    <div class="web-app">
        <section>
            <About heading="Ecommerce Application Development"
                   desc="The rapidly booming user base on the internet has opened doors to many methods of doing shopping and transactions, one of them being the highly recognized and beneficial E-commerce."/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">Ecommerce Development Services</h3>
                        <p class="mb-0">For every single business, be it the entrepreneurs working from home
                            or large corporations, e-commerce surely complements their business in a skyrocketing
                            pattern. The fact that it provides much less time-consuming methods is something that can’t
                            be left unnoticed.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row overflow-h">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="ecommerce/1.png" heading="Online Shopping"
                                             desc=" Online shopping has become a basic necessity in our day to day lives. The simple reason behind it being the ease, comfort, and convenience of staying at one single spot and purchasing anything and everything as one wishes."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="ecommerce/2.png" heading="Internet marketing "
                                             desc="The user is left with an enhanced shopping experience when it comes to availing marketing activities like pricing or even the product features as well."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="ecommerce/3.png" heading="Electronic banking"
                                             desc="Online banking has become such an extensive tool for customers to avail themselves of services such as bill payment, money transfer, balance check, etc. "/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/ecommerce/img.png" alt="#" class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="ecommerce/4.png" heading="Manufacturing"
                                             desc="The smooth transition of electronic exchange is done and a combination of buying and selling as well as information on inventory check is shared among the group of companies making it easier to perform their operation."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="ecommerce/5.png" heading="Auctioning"
                                             desc="Geographical boundaries aren’t a hindrance anymore when it comes to the application of e-commerce to auctions, as with very little expense incurred participation and negotiation can be carried out without giving it a second thought."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="ecommerce/6.png" heading="Wholesale/Retail business"
                                             desc="Any transaction done via online stores where the sale is directly from the business to consumers both in wholesale and retail is called E retailing or online retailing."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="A Great Mobile App Leads to Great Business"
                   desc="We build reliable and high-quality on-demand eCommerce applications to expedite your business."/>
        </section>

        <section class="py-5 bg-gray-1">
            <div class="container">
                <heading heading="Our Recent Ecommerce Application"
                         headingdesc="We brew up your dream application with our hard work, dedication, and years of expertise. We can always go the extra mile to see you satisfied and content."/>

                <div class="row">
                    <div class="col-12 col-lg-4 mb-3">
                        <Component5 img="ecom-02.jpg" heading="Glocart - Ecommerce App"
                                    details="Ecommerce Application Development"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <Component5 img="ecom-01.jpg" heading="Minibells - Ecommerce App"
                                    details="Ecommerce Application Development"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <Component5 img="ecom-03.jpg" heading="Minimart - Ecommerce App"
                                    details="Ecommerce Application Development"/>
                    </div>
                </div>

                <div class="row fl-x-cc mt-5">
                    <div class="col-8 col-lg-3">
                        <router-link to="/our-works/" class="lego-btn xeo-btn-2 btn-block">View All Works</router-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Benefits of eCommerce Integration to Business"
                         headingdesc="Our eCommerce websites provide a better user experience, enhanced engagement, options for scalability, and a competitive edge to meet the changing market trends."/>

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Enhance functionality and security"
                                    desc="Since all operations are software generated, with an eCommerce website your business runs smooth and secure."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Stay a step ahead"
                                    desc="Your customers experience transparency thus place trust on you; giving  your business an extra edge over the competitors"/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Gain more profit"
                                    desc="eCommerce integration will automate many operations and enhance the order fulfillment cycle."/>
                    </div>
                </div>

            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="What makes Xeoscript the best eCommerce development company in Thrissur, Kerala?"
                         headingdesc=" As the number one e-Commerce development company in India, we ensure to have our team keep up with the international standards."/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ecommerce/why-1.jpg" heading="Team of talents"
                                    desc="Our eCommerce developers are well-versed in the changing trends and complex strategies that help your business boost sales."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ecommerce/why-2.jpg" heading="Quality experience"
                                    desc="We have vast experience in eCommerce market trends and have the expertise in dealing with all the development challenges."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ecommerce/why-3.jpg" heading="Transparency"
                                    desc="We always hold onto our core values that also include transparency at every level. You can see all the project updates starting from initiation to execution."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ecommerce/why-4.jpg" heading="Customized services"
                                    desc=" We understand that every business has its own needs. We cater to those requirements and create solutions for smoother operations."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ecommerce/why-5.jpg" heading="Satisfied clientele"
                                    desc="Our testimonials are the visible proof. Over the years, we were lucky to have references from our existing clients showing our satisfied client relations."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="ecommerce/why-6.jpg" heading="Affordable services"
                                    desc="Believe it or not. We give all this and more at affordable pricing without any discrepancies in the quality"/>
                    </div>

                </div>
            </div>
        </section>

<!--        <section>-->
<!--            <GetAQuote/>-->
<!--        </section>-->

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
// import GetAQuote from '../components/GetAQuote';
import Component8 from '../components/single-components/Component-8';
import Component10 from '../components/single-components/Component-10';
import Component5 from '../components/single-components/Component-5';

export default {
    name: 'Ecommerce',
    metaInfo: {
        title: 'Mobile App and Web development Company in Thrissur, Kerala',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Planning on building a large scale, custom-made and high-performing software solution for your business? Look no further as we at Xeoscript Technologies should be able to do the job for you.'
            },
            {
                name: 'keywords',
                content: 'software company in thrissur, it companies in thrissur, web designing company in thrissur, mobile app development company in thrissur,digital marketing services in thrissur'
            }
        ]
    },
    components: { Component5, Component10, Component8, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>

<template>
    <div class="counter-component  bg-fit-c">

        <div class="container">
            <div class="row">
                <template v-for="i in items">
                    <Component12 to="5000" :key="i.id" :img="i.img" :count="i.count" :heading="i.heading"/>

                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Component12 from './single-components/Component-12.vue';

export default {
    components: { Component12 },
    name: 'Counter',
    data () {
        return {
            items: [
                {
                    id: '1',
                    img: '1.png',
                    count: 13,
                    heading: 'Years in Business'
                }, {
                    id: '2',
                    img: '2.png',
                    count: 1400,
                    heading: 'Esteemed Clients'
                }, {
                    id: '3',
                    img: '3.png',
                    count: 1800,
                    heading: 'Projects Completed'
                }, {
                    id: '4',
                    img: '4.png',
                    count: 4500,
                    heading: 'Cups of Coffee'
                }
            ]
        };
    }
};
</script>

<style scoped>
.counter-component {
    background-image: url('../../assets/images/awards/awards-bgi.jpg');
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

@media all and (max-width: 992px) {
    .counter-component {
        flex-direction: column;
    }
}
</style>

<template>
    <div class="our-works-bg" :class="bg">
        <div class="fl-x our-works container">
            <div class="row m-lg-0">
                <div class="img-screen col-12 col-lg-6" :class="['fl-x-' + img_pos]">
                    <img :src="require('@/assets/images/our-works/works/' + img)" alt="">
                </div>
                <div class="img-container col-12 col-lg-6 fl-eq-c py-5"
                     :class="['order-lg-' + align , 'text-' + color]">
                    <div>
                        <img v-if="logo" :src="require('@/assets/images/our-works/logos/' + logo)" class="w-logo"
                             :class="['pr-' + logowidth]" alt="">
                        <p class="mt-3 mb-3 fs-3 fs-lg-6" v-if="heading || heading1">
                            <span :class="'text-' + heading1Color" v-if="heading1" v-html="heading1"
                                  class="font-weight-bold">Easy Win </span>
                            <span v-if="heading" v-html="' - '+heading"> </span>
                        </p>
                        <p v-if="desc" v-html="desc"></p>
                        <div class="fl-x-t">
                            <a target="_blank" :href="android_link">
                                <img src="@/assets/images/our-works/play-store.png" class="mt-3 img-play-store"
                                     v-if="android ==='true'" alt="">
                            </a>
                            <a target="_blank" :href="ios_link">
                                <img src="@/assets/images/our-works/app-store.png" class="mt-3 img-play-store"
                                     v-if="ios ==='true'" alt="">
                            </a>
                        </div>
                        <div class="fl-x-t img-icons mt-4 fs--1">
                            <p class="fl-x-c  mb-0" v-if="img_desc1">
                                <img src="@/assets/images/our-works/platform_white.png" alt=""
                                     class="pr-2 white-icon">
                                <img src="@/assets/images/our-works/platform.png" alt=""
                                     class="pr-2 dark-icon">
                                {{ img_desc1 }}
                            </p>
                            <span class="divider" v-if="img_desc2"></span>
                            <p class="fl-x-c pl-3 mb-0" v-if="img_desc2">
                                <img src="@/assets/images/our-works/company_white.png" alt=""
                                     class="pr-2 white-icon">
                                <img src="@/assets/images/our-works/company.png" alt="" class="pr-2 dark-icon">
                                {{ img_desc2 }}
                            </p>
                            <span class="divider d-none d-lg-block" v-if="website ==='true'"></span>
                            <a target="_blank" :href="web_link" class="fl-x-c pl-3 d-none d-lg-flex mb-0"
                               v-if="website ==='true'">
                                <img src="@/assets/images/our-works/website.png" alt="" class="pr-2 dark-icon">
                                <img src="@/assets/images/our-works/website_white.png" alt="" class="pr-2 white-icon">
                                View Website
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurWorkComponent1',
    data () {
        return {
            align: ''
        };
    },
    beforeMount () {
        if (this.index % 2 === 0) {
            this.align = '';
        } else {
            this.align = 'first';
        }
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        heading: {
            type: String,
            required: false,
            default: ''
        },
        heading1: {
            type: String,
            required: false,
            default: ''
        },
        android: {
            type: String,
            required: false,
            default: ''
        },
        ios: {
            type: String,
            required: false,
            default: ''
        },
        android_link: {
            type: String,
            required: false,
            default: ''
        },
        ios_link: {
            type: String,
            required: false,
            default: ''
        },
        website: {
            type: String,
            required: false,
            default: ''
        },
        web_link: {
            type: String,
            required: false,
            default: ''
        },
        img_desc1: {
            type: String,
            required: false,
            default: ''
        },
        img_desc2: {
            type: String,
            required: false,
            default: ''
        },
        color: {
            type: String,
            required: false,
            default: ''
        },
        bg: {
            type: String,
            required: false,
            default: ''
        },
        heading1Color: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        },
        img: {
            type: String,
            required: false,
            default: ''
        },
        img_pos: {
            type: String,
            required: false,
            default: ''
        },
        logo: {
            type: String,
            required: false,
            default: ''
        },
        logowidth: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">

</style>

<template>
    <div>
        <swiper class="swiper home-slider" data-mousewheel="1" data-parallax="1"
                :options="swiperOption">
            <swiper-slide>
                <div class="item bg-fit-c bg-home-banner3">
                    <div class="container">
                        <div class="row">
                            <div class="col-8 col-md-6 col-lg-5 py-4 py-lg-8 my-1 my-lg-0 text-white">
                                <h1 class="fs-1 fs-lg-7 font-weight-bold" data-swiper-parallax="-600">Tax Gateway
                                </h1>
                                <h2 data-swiper-parallax="-400" class="fs--2 fs-lg-0">Take control of your tax payments.
                                    Streamline tax payments with ease and stay compliant effortlessly</h2>
                                <div data-swiper-parallax="-300" class="fl-x-c mt-4">
                                    <router-link to="/products/" class="btn xeo-btn-5 fs--2 fs-lg-0">Know More
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="item bg-fit-c bg-home-banner">
                    <div class="container">
                        <div class="row">
                            <div class="col-8 col-md-6 col-lg-5 py-4 py-lg-8 my-1 my-lg-0 text-white">
                                <p class="fs-1 fs-lg-7 font-weight-bold" data-swiper-parallax="-600">Mobile Application
                                    <br>Development</p>
                                <p data-swiper-parallax="-400" class="fs--2 fs-lg-0">Creating mobile application that suits
                                    your budget and time frame.</p>
                                <div data-swiper-parallax="-300" class="fl-x-c">
                                    <!--                                    <router-link to="/mobile-application-development-thrissur-kerala/" class="btn xeo-btn-1 fs&#45;&#45;2 fs-lg-0">Know More-->
                                    <!--                                    </router-link>-->
                                    <router-link to="/mobile-application-development-thrissur-kerala/" class="btn xeo-btn-5 fs--2 fs-lg-0">Know More
                                    </router-link>
                                    <a class="home-banner-icon">
                                        <img src="../../assets/images/home-banners/android.png"
                                             alt="">
                                    </a>
                                    <a class="home-banner-icon">
                                        <img src="../../assets/images/home-banners/apple.png"
                                             alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>

            <swiper-slide>
                <div class="item bg-fit-c bg-home-banner2">
                    <div class="container">
                        <div class="row">
                            <div class="col-8 col-md-6 col-lg-5 py-4 py-lg-8 my-1 my-lg-0 text-white">
                                <p class="fs-1 fs-lg-7 font-weight-bold" data-swiper-parallax="-600">Website Design
                                    <br>& Development</p>
                                <p data-swiper-parallax="-400" class="fs--2 fs-lg-0">Boost your online presence. Elevate
                                    your business identity to a whole new level</p>
                                <div data-swiper-parallax="-300" class="fl-x-c">
                                    <router-link to="/web-design-thrissur-kerala/" class="btn xeo-btn-5 fs--2 fs-lg-0">Know More
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>

            <!--            <swiper-slide>-->
            <!--                <div class="item bg-fit-c bg-home-banner3">-->
            <!--                    <div class="container">-->
            <!--                        <div class="row">-->
            <!--                            <div class="col-8 col-md-6 col-lg-5 py-4 py-lg-8 my-1 my-lg-0 text-white">-->
            <!--                                <h1 class="fs-1 fs-lg-7 font-weight-bold" data-swiper-parallax="-600">Ecommerce-->
            <!--                                    Application-->
            <!--                                    <br>Development</h1>-->
            <!--                                <h2 data-swiper-parallax="-400" class="fs&#45;&#45;2 fs-lg-0">Delivering peerless, user-friendly-->
            <!--                                    and interactive eCommerce websites for your business</h2>-->
            <!--                                <div data-swiper-parallax="-300" class="fl-x-c">-->
            <!--                                    <router-link to="/e-commerce-thrissur-kerala/" class="btn xeo-btn-5 fs&#45;&#45;2 fs-lg-0">Know More-->
            <!--                                    </router-link>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </swiper-slide>-->

            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>

        </swiper>
    </div>
</template>

<script>
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
    name: 'Homebanner',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiperOption: {
                // autoHeight: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                // mousewheel: true,
                parallax: true,
                centeredSlides: true,
                // autoplay: true,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1200: {
                        speed: 1500
                    },
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    }
                }
            }
        };
    }
};
</script>

<style scoped>

    .item {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .bg-home-banner {
        background-image: url('../../assets/images/home-banners/Mobile-App.png');
    }

    .bg-home-banner2 {
        background-image: url('../../assets/images/home-banners/home-banners-mob2.jpg');
    }

    .bg-home-banner3 {
        background-image: url('../../assets/images/home-banners/Tax-Gateaway.png');
    }

    .home-banner-icon {
        display: inline-block;
    }

    .home-banner-icon img {
        width: 1.2rem;
        margin-left: 20px;
    }

    .home-slider h1 {
        line-height: 40px;
    }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        width: unset;
        padding: 3px 25px;
        background: #2929293b;
        border-radius: 15px;
    }

    @media (min-width: 992px) {
        .bg-home-banner {
            background-image: url('../../assets/images/home-banners/Mobile-App.png');
        }

        .bg-home-banner2 {
            background-image: url('../../assets/images/home-banners/home-banners2.jpg');
        }

        .bg-home-banner3 {
            background-image: url('../../assets/images/home-banners/Tax-Gateaway.png');
        }

        .home-banner-icon img {
            width: 2rem;
            margin-left: 20px;
        }
    }

</style>
